import React, {useContext} from 'react';
import Link from "next/link";
import {MenuProps} from "../../utils/PropsTypes";
import {ModalContext} from "../modalLayout/ModalLayout";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";

const Menu: React.FC<MenuProps> = ({items, openMenu}) => {
    const modal = useContext(ModalContext);
    const t:any = window.location.pathname === '/en' ? en : ru;

    return (
        <div className={'menu'}>
           {/* <div className={'blur'} />*/}
            <div className={'menu__content'}>
                <ul>
                    {items.map(item =>
                        <li key={item.link}>
                            <Link href={item.link}>
                                <a className={'nav__link'}>
                                    {item.title}
                                </a>
                            </Link>
                        </li>
                    )}
                </ul>
                <div style={{width: '100%', maxWidth: '200px'}}>
                    <button
                        className={"button_base"}
                        onClick={modal.openFormModal}
                    >
                        {t.btn.consultationCost}
                    </button>
                </div>
            </div>

            <div
                className={'close-bg'}
                onClick={() => {
                    openMenu(false)
                }}
            />
        </div>
    );
};

export default Menu;