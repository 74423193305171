import React, {useContext, useEffect, useRef, useState} from 'react';
import Head from "next/head";
import cn from "classnames";
import Link from "next/link";
import Image from "next/image";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {headerMenuOffsetController} from "../../helpers";
import Menu from "../Menu/Menu";
import {PassProps} from "../../utils/PropsTypes";
import {headerNavList, menuList} from "../../utils/InitialParams";
import {useRouter} from "next/router";
import {Box} from "@mui/material";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";
import {ModalContext} from "../modalLayout/ModalLayout";

const Pass: React.FC<PassProps> = ({firstSection}) => {
    const [isOpenMenu, openMenu] = useState(false)
    const header = useRef(null)
    const router = useRouter()
    const modal = useContext(ModalContext);
    const t:any = router.locale === 'en' ? en : ru;

    useEffect(() => {
        const sectionEl = firstSection.current as unknown as HTMLDivElement
        const headerEl = header.current as unknown as HTMLHeadingElement
        const introH = sectionEl?.offsetTop + sectionEl?.offsetHeight;

        headerMenuOffsetController(headerEl, introH);
    }, [firstSection, header, isOpenMenu])
    return (
        <>
            <Head>
                <title>Face 2</title>
                <meta name="description" content="face2 face2pay face2security face2action"/>
                <link rel="icon" href="/favicon.ico"/>
            </Head>

            <div
                className={cn('header-container', {
                    'bg-white': isOpenMenu
                })}
                ref={header}
            >
                <header className={'header container'}>
                    <Box className={'header__logoNav'}>
                        <Link href={'/'}>
                            <a className={'logo-text'}>
                                Face
                                <div className={'face2-logo-container'}>
                                    <Image width={40} height={40} src={'/logo.svg'} alt={'logo'}/>
                                </div>
                            </a>
                        </Link>

                        <nav className={'desktop-header-nav'}>
                            <ul className={'nav-list'}>
                                {
                                    headerNavList.map((item, index) =>
                                        <li key={index}>
                                            <Link href={item.url}>
                                                <p className={'nav__link'}>
                                                    {item.nameUrl}
                                                </p>
                                            </Link>
                                        </li>
                                    )
                                }
                            </ul>

                        </nav>
                    </Box>

                    <div style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                        <div className={'header__btnBlock'}>
                            <button
                                className={"button_base"}
                                onClick={modal.openFormModal}
                            >
                                {t.btn.consultationCost}
                            </button>
                        </div>

                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '16px',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                router.push(router.asPath, router.asPath, {locale: router.locale === 'en' ? 'ru' : 'en'})
                            }}
                        >
                            <Image src={router.locale === 'en' ? '/eng_logo.png' : '/ru_logo.png'} height={18}
                                   width={24}/>
                            <p className={'nav__link'}>
                                {router.locale === 'en' ? 'Eng' : 'Rus'}
                            </p>
                        </Box>
                        <div
                            className={'burger-btn'}
                            onClick={() => openMenu((prev) => !prev)}
                        >
                            <span/>
                        </div>
                    </div>
                    <TransitionGroup component={null}>
                        {isOpenMenu && (<CSSTransition
                            in={isOpenMenu}
                            timeout={300}
                            classNames={'alert'}
                        >
                            <Menu items={menuList} openMenu={openMenu}/>
                        </CSSTransition>)}
                    </TransitionGroup>
                </header>
            </div>
        </>);
};

export default Pass;