import React from 'react';
import Link from "next/link";
import {Divider} from "../Divider/Divider";
import Image from "next/image";
import {footerList} from "../../utils/InitialParams";
import {useRouter} from "next/router";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";

const Footer = () => {
    const router = useRouter();
    const t:any = router.locale === 'en' ? en : ru;

    return (
        <footer className={'footer container'}>
            <div className={'nav-side'}>
                <nav>
                    <ul className={'footer-nav'}>
                        {
                            footerList.map((item, index) =>
                                <li key={index}>
                                    <Link href={item.url}>
                                        <a className={'nav__link'} dangerouslySetInnerHTML={{ __html: item.nameUrl }}>
                                        </a>
                                    </Link>
                                </li>
                            )
                        }
                    </ul>
                </nav>

                <Divider />

                <p className={'nav__link company-name'}>
                    {new Date().getFullYear()} @ Face2
                </p>
            </div>

            <div className={'contacts-block'}>
                <p className={'contacts'}>{t.footer.contacts}</p>

                <div className={'contacts__email-list'}>
                    <div className={'body-text contact-icon-text'}>
                        <div className={'contacts__icon-container'}>
                            <Image height={24} width={24} src={'/message-icon.svg'} alt={'message icon'} />
                        </div>
                        <p><a rel={'nofollow'} href="mailto:support@face2.ru">support@face2.ru</a></p>
                    </div>
                    {/*<div className={'body-text contact-icon-text'}>*/}
                    {/*    <div className={'contacts__icon-container'}>*/}
                    {/*        <Image height={24} width={24} src={'/message-icon.svg'} alt={'message icon'} />*/}
                    {/*    </div>*/}

                    {/*    <p><a rel={'nofollow'} href="mailto:farid.baishev@akbarsdigital.ru">sales@face2.ru</a></p>*/}
                    {/*</div>*/}
                </div>

                <p className={'nav__link company-name'}>
                    {new Date().getFullYear()} @ Face2
                </p>
            </div>
        </footer>
    );
};

export default Footer;